import '../../../App.css';
import { useEffect, useState } from 'react';
import { Box, Input, InputLabel, Modal, Select, TextField } from '@mui/material';
import logowhite from '../../../img/logo48white.png'
import contacts from '../../../img/contact-new.png';
import Navbar from '../../navbar';
/* Фотографии автомобиля */
import foto1 from '../../../img/cars/e-classe e200d/e200d-1.png';
import foto2 from '../../../img/cars/e-classe e200d/e200d-2.png';
import foto3 from '../../../img/cars/e-classe e200d/e200d-3.png';
import foto4 from '../../../img/cars/e-classe e200d/e200d-4.png';
/* Иконки */
import LuggageIcon from '@mui/icons-material/Luggage';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import DoneIcon from '@mui/icons-material/Done';
import StarIcon from '@mui/icons-material/Star';
import PaletteIcon from '@mui/icons-material/Palette';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

import { Carousel } from 'react-carousel-minimal';
import Cadillac from '../../../img/newCadillac.png'
import ModalContacts from "../../modal/ModalContacts";

function Bmw5() {
    const [openContacts, setOpenContacts] = useState(false);
    const handleOpenContacts = () => setOpenContacts(true);
    const handleCloseContacts = () => setOpenContacts(false);

    const [classeAuto, setClasseAuto] = useState();

    const handleChange = (event) => {
        setClasseAuto(event.target.value);
    };

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const data = [
        {
            image: foto1,
        },
        {
            image: foto2,
        },
        {
            image: foto3,
        },
        {
            image: foto4,
        }
    ];

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }


    return (
        <div className="App">
            <Navbar contacts_onClick={handleOpenContacts} />
            <div className="breadcrumb">
                <a className='breadcrumb__text' href="/">Главная</a>
                <p className='breadcrumb__text'>/</p>
                <a className='breadcrumb__text' href="/autopark">Автопарк</a>
                <p className='breadcrumb__text'>/</p>
                <p className='breadcrumb__text' >BMW 5-er</p>
            </div>
            <div className="main_background">
                <section name='service' id="service">
                    <p className='header-name-car'>BMW 5 series</p>
                    <div className='gallery-car'>
                        <Carousel
                            data={data}
                            time={10000}
                            width="100%"
                            height="700px"
                            captionStyle={captionStyle}
                            radius="10px"
                            slideNumber={false}
                            slideNumberStyle={slideNumberStyle}
                            captionPosition="bottom"
                            automatic={true}
                            dots={true}
                            pauseIconColor="white"
                            pauseIconSize="40px"
                            slideBackgroundColor="darkgrey"
                            slideImageFit="cover"
                            thumbnails={false}
                            thumbnailWidth="100px"
                            style={{
                                textAlign: "center",
                                maxWidth: "100%",
                                maxHeight: "auto",
                                margin: "40px auto",
                            }}
                        />
                    </div>
                    <div className='settings-car-grid'>
                        <div className='settings-car'>
                            <PaletteIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Цвет кузова</p>
                                <p className='settings-p'>Черный</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <AirlineSeatReclineNormalIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Сидячие места</p>
                                <p className='settings-p'>3 места</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <LuggageIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Кол-во багажа</p>
                                <p className='settings-p'>3 багажа</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <CurrencyRubleIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Цена</p>
                                <p className='settings-p'>от 2000 ₽/час</p>
                            </div>
                        </div>
                    </div>
                    <section name='description' className='description-grid'>
                        <div className='description-car'>
                            <div>
                                <p className='description-header'>Описание E-classe E200d</p>
                                <p className='description-p'>Mercedes-Benz E220d AMG - это элегантный и высокотехнологичный автомобиль, который сочетает в себе изысканный дизайн, высочайшие стандарты комфорта и передовые технологии. Спортивная эстетика и превосходная динамика делают этот автомобиль идеальным выбором для комфортных и стильных поездок.</p>
                                <p className='description-p'>Выбрав нашу компанию для аренды персонального водителя на Mercedes-Benz E220d AMG, вы можете быть уверены, что ваша поездка будет удовлетворять самым высоким стандартам качества и комфорта. Обратитесь к нам сегодня, чтобы организовать аренду персонального водителя и насладиться роскошью  Mercedes-Benz.</p>
                                <p className='description-p'>У нас широкий выбор автомобилей линейки E-класса: Mercedes-Benz E200d, Mercedes-Benz E220d AMG, Mercedes-Benz E300d AMG, Mercedes-Benz E350d AMG в черном, белом и сером цвете кузова (по наличию уточняйте по телефону).</p>
                            </div>
                        </div>
                        <div className='tariff-car'>
                            <div>
                                <p className='description-p'>Стоимость аренды</p>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 3-ти часов</p>
                                    <p className='tariff-p'>3000 ₽/час + 1ч. подачи</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 6-ти часов</p>
                                    <p className='tariff-p'>3000 ₽/час + 1ч. подачи</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 1 дня</p>
                                    <p className='tariff-p'>3000 ₽/час + 1ч. подачи</p>
                                </div>
                                <p className='description-p-2'>Трансферы</p>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Шереметьево</p>
                                    <p className='tariff-p'>5000 ₽</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Внуково</p>
                                    <p className='tariff-p'>5000 ₽</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Домодедово</p>
                                    <p className='tariff-p'>7000 ₽</p>
                                </div>
                                <div className='tariff-grid-'>
                                    <p className='tariff-mp'>Минимальный заказ от 1-го часа. Стоимость минимального заказа - 1800 ₽/час. Подача за МКАД рассчитывается отдельно. Дальние поездки рассчитываются по километражу, включая часы работы.  </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <p className='description-header'>Похожие автомобили</p>
                <div className='autopark__grid'>
                    <div className='autopark__card'>
                        <div className='card__photo'>
                            <img src={foto1} className="card__photo_img" alt="logo" />
                        </div>
                        <div className='card__description'>
                            <p className='card__description_header'>Mercedes-Benz Maybach W222</p>
                            <div className='card__carSpecific'>
                                <div className='carSpecific-man'>
                                    <PeopleIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <LuggageIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 2</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <AccessTimeIcon className='icon_carSpecific' />
                                    <p className='text-specific'> от 30 мин.</p>
                                </div>
                            </div>
                            <div className='card_carAction'>
                                <div className='carSpeific-man'>
                                    <p className='carSpeific-man-text'>от <b className="b-price">1700 ₽</b> за час</p>
                                </div>
                                <div className='carSpeific-man'>
                                    <button className='card__btn-view'>Посмотреть</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='autopark__card'>
                        <div className='card__photo'>
                            <img src={foto1} className="card__photo_img" alt="logo" />
                        </div>
                        <div className='card__description'>
                            <p className='card__description_header'>Mercedes-Benz S-classe W223</p>
                            <div className='card__carSpecific'>
                                <div className='carSpecific-man'>
                                    <PeopleIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <LuggageIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <AccessTimeIcon className='icon_carSpecific' title="Минимальное кол-во часов для бронирования" />
                                    <p className='text-specific'> от 3 часов</p>
                                </div>
                            </div>
                            <div className='card_carAction'>
                                <div className='carSpeific-man'>
                                    <p className='carSpeific-man-text'>от <b className="b-price">5000 ₽</b> за час</p>
                                </div>
                                <div className='carSpeific-man'>
                                    <button className='card__btn-view'>Посмотреть</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='newCar'>
                    <div className='service__header'>
                        <p className='header'>Попробуйте</p>
                        <p className='header-plain'>Новый автомобиль, танк на московских дорогах. <br /> Популярен в аренде у Госструктур.</p>
                    </div>
                    <div className='newCar__grid'>
                        <div className='newCar-specifications'>
                            <p className='header__newCar'>Cadillac Escalade</p>
                            <div className='specif-grid'>
                                <div>
                                    <p className='carNew__description_plain'><DoneIcon className='icon-succes' />Полный привод</p>
                                    <p className='carNew__description_plain'><DoneIcon className='icon-succes' />2022 года выпуска</p>
                                    <p className='carNew__description_plain'><DoneIcon className='icon-succes' />7 мест</p>
                                </div>
                                <div>
                                    <p className='carNew__description_plain'> <DoneIcon className='icon-succes' />Для деловых поездок</p>
                                    <p className='carNew__description_plain'><DoneIcon className='icon-succes' />Идеален для свадьбы</p>
                                    <p className='carNew__description_plain'><StarIcon className='icon-succes' />Популярен у Госструктур</p>
                                </div>
                            </div>
                            <button className='btn__newCar'>Посмотреть детально</button>
                        </div>
                        <div className='newCar-img-block'>
                            <img src={Cadillac} className="newCar-img" alt="logo" />
                        </div>
                    </div>

                </section>
                <section name='footer'>
                    <div className='footer'>
                        <div className='footer-grid'>
                            <div> <img src={logowhite} className='logo-white' alt="logo" />
                                <p className='foot-text'>
                                    Сервис персональных водителей и аренды автомобилей бизнес и премиум класса.
                                </p>
                            </div>
                            <div>
                                <p className='foot-text-big'>Навигация</p>
                                <hr></hr>
                                <div className='ft-navigate'>
                                    <a className='foot-text'>Оставить заявку</a>
                                    <a className='foot-text' href="#service">Услуги</a>
                                    <a className='foot-text' href='/autopark'>Автопарк</a>
                                </div>
                            </div>
                            <div>
                                <p className='foot-text-big'>Контакты</p>
                                <hr></hr>
                                <div className='ft-navigate'>
                                    <a className='foot-text'>8-800-302-60-78</a>
                                    <a className='foot-text'>lbc.zakaz@gmail.com</a>
                                    <div>
                                        <a href='https://instagram.com/luxury.black.car?igshid=MzRlODBiNWFlZA=='><InstagramIcon className='icon-footer' /></a>
                                        <WhatsAppIcon className='icon-footer' />
                                        <TelegramIcon className='icon-footer' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='foot-back'>
                            <p className='foot-back-text'>© 2024 "RIDE4U". Все права защищены.</p>
                            <p className='foot-back-text'>Политика конфиденциальности</p>
                        </div>
                    </div>
                </section>
                <ModalContacts />
            </div>
        </div>
    );
}

export default Bmw5;
