import React from 'react'
import '../App.css';
import DoneIcon from '@mui/icons-material/Done';
import StarIcon from '@mui/icons-material/Star';
import G63png from '../img/cars/g63-classe/g63-png.png'

class AdvertisingCarG63 extends React.Component {
    render() {
        return <section className='newCar'>
            <div className='service__header'>
                <p className='header'>Мы советуем</p>
                <p className='header-plain'>Новый автомобиль, кубик на московских дорогах. <br />Самая популярная модель в аренде!</p>
            </div>
            <div className='newCar__grid'>
                <div className='newCar-specifications'>
                    <p className='header__newCar'>Mercedes-Benz G63 AMG</p>
                    <div className='specif-grid'>
                        <div>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />Полный привод</p>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />2022 года выпуска</p>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />4 места</p>
                        </div>
                        <div>
                            <p className='carNew__description_plain'> <DoneIcon className='icon-succes' />Для деловых поездок</p>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />Идеален для кортежа</p>
                            <p className='carNew__description_plain'><StarIcon className='icon-succes' />Популярен у Госструктур</p>
                        </div>
                    </div>
                    <a className='btn__newCar' href='/g63_AMG'>Посмотреть детально</a>
                </div>
                <div className='newCar-img-block'>
                    <img src={G63png} className="newCar-img" alt="logo" />
                </div>
            </div>
        </section>;
    }
}

export default AdvertisingCarG63;