import '../../../App.css';
import { useEffect, useState } from 'react';
import AppConfig from "../../../AppConfig";
import { Box, Input, InputLabel, Modal, Select, TextField } from '@mui/material';
import logo from '../../../img/logo48black.png'
import contacts from '../../../img/contact-new.png';
import AdvertisingCar from '../../AdvCar.js'
import Footer from '../../footer';
import Navbar from '../../navbar';
/* Фотографии автомобиля */
import foto1 from '../../../img/cars/e-classe e220d dorest/e220d-dorest-1.png';
import foto2 from '../../../img/cars/e-classe e220d dorest/e220d-dorest-2.png';
import foto3 from '../../../img/cars/e-classe e220d dorest/e220d-dorest-3.png';
import foto4 from '../../../img/cars/e-classe e220d dorest/e220d-dorest-4.png';
import foto5 from '../../../img/cars/e-classe e220d dorest/e220d-dorest-5.png';
/* Иконки */
import LuggageIcon from '@mui/icons-material/Luggage';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import PaletteIcon from '@mui/icons-material/Palette';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

import { Carousel } from 'react-carousel-minimal';
import e200d from '../../../img/cars/e-classe e200d/main.png';
import eclasse from '../../../img/w213new-main-park.png';
import e300d from '../../../img/cars/e-classe e300d amg/e300d-main.png';
import ModalContacts from "../../modal/ModalContacts";

function E220dDorest() {
    const [openContacts, setOpenContacts] = useState(false);
    const handleOpenContacts = () => setOpenContacts(true);
    const handleCloseContacts = () => setOpenContacts(false);

    const [openOrder, setOpenOrder] = useState(false);
    const handleOpenOrder = () => setOpenOrder(true);
    const handleCloseOrder = () => setOpenOrder(false);

    const [classeAuto, setClasseAuto] = useState();

    const handleChange = (event) => {
        setClasseAuto(event.target.value);
    };

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const data = [
        {
            image: foto1,
        },
        {
            image: foto2,
        },
        {
            image: foto3,
        },
        {
            image: foto4,
        },
        {
            image: foto5,
        }
    ];

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }


    return (
        <div className="App">
            <Navbar contacts_onClick={handleOpenContacts} />
            <div className="breadcrumb">
                <a className='breadcrumb__text' href="/">Главная</a>
                <p className='breadcrumb__text'>/</p>
                <a className='breadcrumb__text' href="/autopark">Автопарк</a>
                <p className='breadcrumb__text'>/</p>
                <p className='breadcrumb__text' >Mercedes-Benz E220d Dorest.</p>
            </div>
            <div className="main_background">
                <section name='service' id="service">
                    <p className='header-name-car'>Mercedes-Benz E220d Dorest.</p>
                    <div className='gallery-car'>
                        <Carousel
                            data={data}
                            time={10000}
                            width="100%"
                            height="700px"
                            captionStyle={captionStyle}
                            radius="10px"
                            slideNumber={false}
                            slideNumberStyle={slideNumberStyle}
                            captionPosition="bottom"
                            automatic={true}
                            dots={true}
                            pauseIconColor="white"
                            pauseIconSize="40px"
                            slideBackgroundColor="darkgrey"
                            slideImageFit="cover"
                            thumbnails={false}
                            thumbnailWidth="100px"
                            style={{
                                textAlign: "center",
                                maxWidth: "100%",
                                maxHeight: "auto",
                                margin: "40px auto",
                            }}
                        />
                    </div>
                    <div className='settings-car-grid'>
                        <div className='settings-car'>
                            <PaletteIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Цвет кузова</p>
                                <p className='settings-p'>Черный</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <AirlineSeatReclineNormalIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Сидячие места</p>
                                <p className='settings-p'>3 места</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <LuggageIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Кол-во багажа</p>
                                <p className='settings-p'>3 багажа</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <CurrencyRubleIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Цена</p>
                                <p className='settings-p'>от 2000 ₽/час</p>
                            </div>
                        </div>
                    </div>
                    <section name='description' className='description-grid'>
                        <div className='description-car'>
                            <div>
                                <p className='description-header'>Описание E220d Dorest.</p>
                                <p className='description-p'>Обратившись к нам для аренды Mercedes-Benz E220d Dorest. с персональным водителем, вы можете быть уверены в высоком качестве услуг и комфорте во время поездки. Наша главная цель - превзойти ваши ожидания и сделать вашу поездку еще более особенной и роскошной.</p>
                                <p className='description-p'>В автомобиле Mercedes-Benz E220d Dorest. вы найдете просторные сиденья и тщательно продуманные детали, которые создают атмосферу комфорта, чтобы вы могли расслабиться и насладиться поездкой.</p>
                                <p className='description-p'>У нас широкий выбор автомобилей линейки E-класса: Mercedes-Benz E200d, Mercedes-Benz E220d AMG, Mercedes-Benz E300d AMG, Mercedes-Benz E350d AMG в черном, белом и сером цвете кузова (по наличию уточняйте по телефону).</p>
                            </div>
                        </div>
                        <div className='tariff-car'>
                            <div>
                                <p className='description-p'>Стоимость аренды</p>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 3-ти часов</p>
                                    <p className='tariff-p'>1850 ₽/час</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 6-ти часов</p>
                                    <p className='tariff-p'>1700 ₽/час</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 1 дня</p>
                                    <p className='tariff-p'>1500 ₽/час</p>
                                </div>
                                <p className='description-p-2'>Трансферы</p>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Шереметьево</p>
                                    <p className='tariff-p'>3500 ₽</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Внуково</p>
                                    <p className='tariff-p'>3500 ₽</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Домодедово</p>
                                    <p className='tariff-p'>4000 ₽</p>
                                </div>
                                <div className='btn-order-grid'>
                                    <a className='btn-main-order'
                                        onClick={() => {
                                            handleOpenOrder();
                                        }}>Забронировать</a>
                                    <div className='container-btn-grid-2pc'>
                                        <a href='https://wa.me/79165252874?text=Добрый день, меня интересует аренда Mercedes-Benz E300d AMG с персональным водителем.' className='btn-messanger'>Написать в Whatapp</a>
                                        <a href={AppConfig.Site.Links.telegram} className='btn-messanger'>Написать в Telegram</a>
                                    </div>
                                </div>
                                <div className='tariff-grid-'>
                                    <p className='tariff-mp text-margin'>Минимальный заказ от 2-х часов. Стоимость минимального заказа - 1850 ₽/час. Подача за МКАД рассчитывается отдельно. Дальние поездки рассчитываются по километражу, включая часы работы.  </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <p className='description-header'>Похожие автомобили</p>
                <div className='autopark__grid'>
                    <div className='autopark__card'>
                        <div className='card__photo'>
                            <img src={e200d} className="card__photo_img" alt="logo" />
                        </div>
                        <div className='card__description'>
                            <p className='card__description_header'>Mercedes-Benz E200d</p>
                            <div className='card__carSpecific'>
                                <div className='carSpecific-man'>
                                    <PeopleIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <LuggageIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 2</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <AccessTimeIcon className='icon_carSpecific' />
                                    <p className='text-specific'> от 30 мин.</p>
                                </div>
                            </div>
                            <div className='card_carAction'>
                                <div className='carSpeific-man'>
                                    <p className='carSpeific-man-text'>от <b className="b-price">1700 ₽</b> за час</p>
                                </div>
                                <a className='carSpeific-man' href='/e200d'>
                                    <button className='card__btn-view'>Посмотреть</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='autopark__card'>
                        <div className='card__photo'>
                            <img src={eclasse} className="card__photo_img" alt="logo" />
                        </div>
                        <div className='card__description'>
                            <p className='card__description_header'>Mercedes-Benz E220d AMG</p>
                            <div className='card__carSpecific'>
                                <div className='carSpecific-man'>
                                    <PeopleIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <LuggageIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 2</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <AccessTimeIcon className='icon_carSpecific' />
                                    <p className='text-specific'> от 30 мин.</p>
                                </div>
                            </div>
                            <div className='card_carAction'>
                                <div className='carSpeific-man'>
                                    <p className='carSpeific-man-text'>от <b className="b-price">1700 ₽</b> за час</p>
                                </div>
                                <a className='carSpeific-man' href='/e220d_amg'>
                                    <button className='card__btn-view'>Посмотреть</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='autopark__card'>
                        <div className='card__photo'>
                            <img src={e300d} className="card__photo_img" alt="logo" />
                        </div>
                        <div className='card__description'>
                            <p className='card__description_header'>Mercedes-Benz E300d AMG</p>
                            <div className='card__carSpecific'>
                                <div className='carSpecific-man'>
                                    <PeopleIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <LuggageIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 2</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <AccessTimeIcon className='icon_carSpecific' />
                                    <p className='text-specific'> от 30 мин.</p>
                                </div>
                            </div>
                            <div className='card_carAction'>
                                <div className='carSpeific-man'>
                                    <p className='carSpeific-man-text'>от <b className="b-price">1700 ₽</b> за час</p>
                                </div>
                                <a className='carSpeific-man' href='/e300d_AMG'>
                                    <button className='card__btn-view'>Посмотреть</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <AdvertisingCar />
                <Footer />
                <Modal
                    keepMounted
                    open={openOrder}
                    onClose={handleCloseOrder}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box className="modal-box-order">
                        <p className='modal-header-order'>Забронировать автомобиль</p>
                        <p className='modal-text-order'>Mercedes-Benz E220d Dorest.</p>
                        <TextField label="Ваше имя" size="small" className='modal-input'>По всем вопросам пишите: lbc.zakaz@gmail.com</TextField>
                        <TextField label="Ваш телефон" size="small" className='modal-input'>Наш адрес: Дубининская ул., 71, стр. 11</TextField>
                        <TextField label="Комментарий к заказу" size="small" className='modal-input'>Наш адрес: Дубининская ул., 71, стр. 11</TextField>
                        <a className='btn-modal-order'>Забронировать</a>
                        <p className='modal-mt-order'>Нажимая кнопку «Забронировать» вы соглашаетесь с <a className='a-policy' href='/privacy-policy'>условиями обработки персональных данных.</a></p>
                    </Box>
                </Modal>
                <ModalContacts openContacts={openContacts} handleCloseContacts={handleCloseContacts}/>
            </div>
        </div>
    );
}

export default E220dDorest;
