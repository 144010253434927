import './App.css';
import AppConfig from './AppConfig'

import AdvertisingCar from './pages/AdvCar';
import Footer from './pages/footer';
import Navbar from './pages/navbar';

import ModalContacts from "./pages/modal/ModalContacts";

import gazprom from './img/partners/Gazprom.png';
import forum from './img/partners/forum.png';
import gtk from './img/partners/gtk-biz-logo.png';
import yandex from './img/partners/yandex-logo.png';
import topcar from './img/partners/topcar-logo.png';

import transfer from './img/transfer.png';
import logoblack from './img/logo48black.png'
import logowhite from './img/logo48white.png'
import wedding from './img/wedding.png';
import vClass from './img/cars/v-classe/v-classe-main.png';
import moreAuto from './img/moreAuto.jpg';
import driver from './img/econom-form.jpeg';
import contacts from './img/contact-new.png';
import outStory2 from './img/outStory-2.png';
import cadillacmain from './img/cars/Cadillac Escalade/Cadillac-main.png';
import eclasse from './img/w213new-main-park.png';
import sclasse from './img/s-main-park.png';
import gls450 from './img/cars/gls-classe 450/gls450-main.png';
import g63classe from './img/cars/g63-classe/g63-classe-main.png';
import w223classe from './img/cars/s-classe-w223/s-classe-w223-main.png';
import maybachclasse from './img/may222-main.png';
import w223mayclasse from './img/w223maybach-main-park.png';

import RR from './img/outStory-RR.jpg'
import Cadillac from './img/newCadillac.png'
import DoneIcon from '@mui/icons-material/Done';
import StarIcon from '@mui/icons-material/Star';
import PeopleIcon from '@mui/icons-material/People';
import LuggageIcon from '@mui/icons-material/Luggage';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useState, useEffect } from 'react';
import { Box, Input, InputLabel, Modal, Select, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import CarRentalIcon from '@mui/icons-material/CarRental';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { db } from "./firebase.js";
import { collection, addDoc } from "firebase/firestore";
import sprinterwhite from "./img/cars/sprinter-white/sprinter-white-main.png";
import sprintervip from "./img/cars/sprinter-vip/sprinter-vip-main.png";
import bus from "./img/cars/bus/bus-main.png";

function MainPage() {
    const [newDate, setNewDate] = useState(""); // Дата начала 
    const [newName, setNewName] = useState(""); // Имя заказчика
    const [newPhone, setNewPhone] = useState(""); // Номер телефона
    const usersCollectionRef = collection(db, "orders");
    const createOrder = async () => {
        await addDoc(usersCollectionRef, {
            date: newDate,
            status_order: "Новый с Сайта",
            classeAuto: classeAuto,
            time: "",
            otkuda: "",
            dateEnd: "",
            timeEnd: "",
            kuda: "",
            commentAdress: "",
            tablet: "",
            typeTransport: "",
            classmodel: "",
            commentPark: "На " + newDate + "г. , класс автомобиля: " + classeAuto + ", Клиент: " + newName + ", тел.: " + newPhone,
            auto: "",
            driver: "",
            priceHourCompany: "",
            priceHourExecutor: "",
            amountHourCompany: "",
            amountHourExecutor: "",
            priceOneKmCompany: "",
            priceOneKmExecutor: "",
            totalOneKmCompany: "",
            totalOneKmExecutor: "",
            deliveryHourCompany: "",
            deliveryHourExecutor: "",
            dopServicesCompany: "",
            dopServicesExecutor: "",
            prePayCompany: "",
            prePayExecutor: "",
            agentPayCompany: "",
            price: "",
            pricedriver: "",
            commentdriver: "",
            name_partners: "",
        });
        if (createOrder) {
            handleOpenSuccess();
        } else {
            alert("Произошла ошибка отправки заказа :( Позвоните нам по телефону.");
        }
    };

    useEffect(() => {
        const anchors = ["service"];

        let url = window.location.href;
        let idx = url.indexOf("#");
        let hash = idx != -1 ? url.substring(idx + 1) : "";
        if(anchors.includes(hash)) {
            window.location.href = "#" + hash;
            window.history.replaceState(null, null, url);
        }
        console.log(hash);
    }, []);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openVan, setOpenVan] = useState(false);
    const handleOpenVan = () => setOpenVan(true);
    const handleCloseVan = () => setOpenVan(false);

    const [openWedding, setOpenWedding] = useState(false);
    const handleOpenWedding = () => setOpenWedding(true);
    const handleCloseWedding = () => setOpenWedding(false);

    const [openEvents, setOpenEvents] = useState(false);
    const handleOpenEvents = () => setOpenEvents(true);
    const handleCloseEvents = () => setOpenEvents(false);

    const [openTrezv, setOpenTrezv] = useState(false);
    const handleOpenTrezv = () => setOpenTrezv(true);
    const handleCloseTrezv = () => setOpenTrezv(false);

    const [openContacts, setOpenContacts] = useState(false);
    const handleOpenContacts = () => setOpenContacts(true);
    const handleCloseContacts = () => setOpenContacts(false);

    const [openSuccess, setOpenSuccess] = useState(false);
    const handleOpenSuccess = () => setOpenSuccess(true);
    const handleCloseSuccess = () => setOpenSuccess(false);

    const [classeAuto, setClasseAuto] = useState();

    const handleChange = (event) => {
        setClasseAuto(event.target.value);
    };

    return (
        <div className="App">
            <div className="main_background">
                <Navbar contacts_onClick={handleOpenContacts} />
                <div className='grid-block'>
                    <a className='block-main-1'>
                        <div className='backdrop-filter'>
                            <p className='clssse-big-font'>Luxury cars in Moscow with a personal driver</p>
                            <p className='clssse-mini-font'>Аренда премиальных автомобилей с персональным водителем на каждый день.</p>
                            <p className='clssse-mini-font'>Работаем по г.Москва и Московской области с 2012 года.</p>
                        </div>
                    </a>
                </div>
                <div className='box-order'>
                    <div className='box-order-input'>

                        <InputLabel shrink htmlFor="bootstrap-input" className='labelInput'>
                            Класс автомобиля:
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age"
                            onChange={handleChange}
                            value={classeAuto}
                            className='classeAuto-input'
                        >
                            <MenuItem value="Mercedes-Benz E-classe">Mercedes-Benz E-classe</MenuItem>
                            <MenuItem value="Mercedes-Benz E-classe New">Mercedes-Benz E-classe New</MenuItem>
                            <MenuItem value="Mercedes-Benz E-classe E300d">Mercedes-Benz E-classe E300d</MenuItem>
                            <MenuItem value="Mercedes-Benz V-classe">Mercedes-Benz V-classe</MenuItem>
                            <MenuItem value="Mercedes-Benz S-classe W222">Mercedes-Benz S-classe W222</MenuItem>
                            <MenuItem value="Mercedes-Benz S-classe W223">Mercedes-Benz S-classe W223</MenuItem>
                            <MenuItem value="Mercedes-Benz Maybach W222">Mercedes-Benz Maybach W222</MenuItem>
                            <MenuItem value="Mercedes-Benz Maybach W223">Mercedes-Benz Maybach W223</MenuItem>
                            <MenuItem value="Mercedes-Benz GLS450">Mercedes-Benz GLS450</MenuItem>
                            <MenuItem value="Cadillac Escalade">Cadillac Escalade</MenuItem>
                            <MenuItem value="Mercedes-Benz G63 AMG">Mercedes-Benz G63 AMG</MenuItem>
                            <MenuItem value="Mercedes-Benz Sprinter White">Mercedes-Benz Sprinter White</MenuItem>
                            <MenuItem value="Mercedes-Benz Sprinter VIP">Mercedes-Benz Sprinter VIP</MenuItem>
                            <MenuItem value="Автобус Yutong ZK6122H9">Автобус Yutong ZK6122H9</MenuItem>
                        </Select>
                    </div>
                    <div className='box-order-input'>
                        <InputLabel shrink htmlFor="bootstrap-input" className='labelInput'>
                            Дата подачи:
                        </InputLabel>
                        <TextField size="small" type='date' className='dateAuto-input' onChange={(event) => {
                            setNewDate(event.target.value);
                        }} />
                    </div>
                    <div className='box-order-input'>
                        <InputLabel shrink htmlFor="bootstrap-input" className='labelInput'>
                            Ваше имя:
                        </InputLabel>
                        <TextField size="small" type='text' className='classeAuto-input' onChange={(event) => {
                            setNewName(event.target.value);
                        }} />
                    </div>
                    <div className='box-order-input'>
                        <InputLabel shrink htmlFor="bootstrap-input" className='labelInput'>
                            Ваш телефон:
                        </InputLabel>
                        <TextField size="small" type='phone' className='classeAuto-input' onChange={(event) => {
                            setNewPhone(event.target.value);
                        }} />
                    </div>
                    <div className='box-order-input'>
                        <a className='btn-add-order' onClick={createOrder}><span className='t-btn-order' >Заказать</span></a>
                    </div> {/* <CarRentalIcon className='icon-order' /> */}
                </div>
                <section name='partners'>
                    <div className='partners-grid'>
                        <img src={gazprom} className="partners-img" alt="logo" />
                        <img src={forum} className="partners-img" alt="logo" />
                        <img src={gtk} className="partners-img-no" alt="logo" />
                        <img src={yandex} className="partners-img" alt="logo" />
                        <img src={topcar} className="partners-img" alt="logo" />
                    </div>
                </section>
                <section name='service' id="service">
                    <div className='service__header'>
                        <p className='header'>Услуги</p>
                        <p className='header-plain'>Приглашаем вас опробывать наши услуги,<br /> мы гарантируем что вы останетесь довольны.</p>
                    </div>
                    <div className='service__grid'>
                        <div className='service__card'>
                            <div className='card__photo'>
                                <img src={transfer} className="card__service_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Трансферы Аэропорт</p>
                                <p className='card__description_plain'>С нами вы никогда не опоздаете в Аэропорт. Водитель приедет во время и поможет с багажом.</p>
                                <button
                                    className='card__btn'
                                    onClick={() => {
                                        handleOpen();
                                    }}
                                >Посмотреть</button>
                            </div>
                        </div>
                        <div className='service__card'>
                            <div className='card__photo'>
                                <img src={vClass} className="card__service_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Для всей семьи</p>
                                <p className='card__description_plain'>Заказывайте минивен Mercedes-Benz V-classe, для всей семьи. Комфортный автомбиль для трансфера.</p>
                                <button className='card__btn'
                                    onClick={() => {
                                        handleOpenVan();
                                    }}>Посмотреть</button>
                            </div>
                        </div>
                        <div className='service__card'>
                            <div className='card__photo'>
                                <img src={wedding} className="card__service_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Свадебные мероприятия</p>
                                <p className='card__description_plain'>Нужен премиальный авто для свадьбы? Тогда обращайтесь к нам!</p>
                                <button className='card__btn'
                                    onClick={() => {
                                        handleOpenWedding();
                                    }}>Посмотреть</button>
                            </div>
                        </div>
                        <div className='service__card'>
                            <div className='card__photo'>
                                <img src={moreAuto} className="card__service_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Для мероприятий</p>
                                <p className='card__description_plain'>Подача нескольких авто для проведения корпоративных мероприятий, встреч, форумов.</p>
                                <button className='card__btn'
                                    onClick={() => {
                                        handleOpenEvents();
                                    }}>Посмотреть</button>
                            </div>
                        </div>
                        <div className='service__card'>
                            <div className='card__photo'>
                                <img src={driver} className="card__service_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Трезвый водитель</p>
                                <p className='card__description_plain'>Нужно перевезти ваш автомобиль? Под эту задачу мы предоставим только проверенных водителей.</p>
                                <button className='card__btn'
                                    onClick={() => {
                                        handleOpenTrezv();
                                    }}
                                >Посмотреть</button>
                                <button className='card__btn'
                                    onClick={() => {
                                        handleOpenTrezv();
                                    }}
                                >Посмотреть</button>
                            </div>
                        </div>
                    </div>
                </section>

                <section name='service'>
                    <div className='service__header'>
                        <p className='header'>Автопарк</p>
                        <p className='header-plain'>Приглашаем вас опробовать наши услуги,<br /> мы гарантируем что вы останетесь довольны.</p>
                    </div>
                    <div className='autopark__grid'>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={vClass} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz V-classe</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 2</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3-х часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">1 650 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/vclasse'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={sprinterwhite} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz Sprinter White</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 19</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 10</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 4 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">2 000 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/sprinter-white-vip'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={sprintervip} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz Sprinter VIP</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 19</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 10</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 4 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">3 500 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/sprinter-vip'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={bus} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Автобус Yutong ZK6122H9</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 50</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 50</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 4 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">2 700 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/yutong_ZK6122H9'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={eclasse} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz E220d AMG</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 2</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 2-х часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">1 500 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/e220d_amg'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={sclasse} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz S-classe W222</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 2</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3-х часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">2 800 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/sclasse-w222'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={w223classe} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz S-classe W223</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' title="Минимальное кол-во часов для бронирования" />
                                        <p className='text-specific'> от 3-х часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">4 000 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/sclasse-w223'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={maybachclasse} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz Maybach W222</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 2</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3-х часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">3 200 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/maybach_w222'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/*<div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={w223mayclasse} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz Maybach W223</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 4-х часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">10 000 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/maybach_w223'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>*/}
                        {/*<div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={gls450} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz GLS450</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3-х часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">6 500 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/gls450'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={cadillacmain} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Cadillac Escalade</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'>7</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'>3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3-х часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">6 500 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/cadillac_escalade'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={g63classe} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz G63 AMG</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 5-ти часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">8 000 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/g63_AMG'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                    <div className='btn-box'>
                        <a className='btn-blue' href='/autopark'>Посмотреть весь автопарк</a>
                    </div>
                </section>
                <section className='outStory'>
                    <div className='service__header'>
                        <p className='header'>Коротко о нас</p>
                        <p className='header-plain'>Люксовый сервис, с проффесиональными персональными <br /> водителями и премиальным обслуживанием.</p>
                    </div>
                    <div className='outStory__grid-1'>
                        <div className='outStory__grid-1-text'>
                            <p className='outStory_text'>Работаем на рынке аренды персонального водителя с автомобилем с 2014 года.</p>
                        </div>
                        <div className='outStory__grid-1-text'>
                            <p className='outStory_text'>Аренда автомобиля с водителем, трезвый водитель, трансферы, поездки в другие города, сопровождение, автомобили на свадьбу, заказ минивэнов, заказ микроавтобусов, аренда автомобиля для фотосессии.</p>
                        </div>
                    </div>
                    <div className='outStory__grid'>
                        <div className='story__imgBlock'>
                            <img src={transfer} className="story__img" alt="logo" />
                        </div>
                        <div className='story__imgBlock'>
                            <img src={outStory2} className="story__img" alt="logo" />
                        </div>
                        <div className='story__imgBlock'>
                            <img src={RR} className="story__img" alt="logo" />
                        </div>
                    </div>
                </section>
                <AdvertisingCar />
                <Footer />
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box className="modal-box">
                        <div className='modal-photo'>
                            <img src={transfer} className="card__photo_img" alt="logo" />
                        </div>
                        <p className='modal-header'>Трансферы АЭРОПОРТ</p>
                        <p className='modal-text'>Наша цель - сделать вашу поездку максимально приятной и беззаботной. Мы гарантируем пунктуальность, профессионализм и безопасность наших водителей, чтобы вы могли полностью расслабиться и насладиться поездкой.</p>
                        <p className='modal-text'>Наши профессиональные водители будут ждать вас в аэропорту с табличкой с вашим именем, чтобы вы могли легко идентифицировать их. Они окажут помощь с багажом и сопроводят вас до автомобиля, который будет ожидать вас на парковке.</p>
                        <p className='modal-text'>Если вам нужны услуги персонального водителя для провождения или встречи в аэропорту, обращайтесь к нам.</p>


                    </Box>
                </Modal>
                <Modal
                    keepMounted
                    open={openVan}
                    onClose={handleCloseVan}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box className="modal-box">
                        <div className='modal-photo'>
                            <img src={vClass} className="card__photo_img" alt="logo" />
                        </div>
                        <p className='modal-header'>Минивен для всей семьи/Коллег</p>
                        <p className='modal-text'>Mercedes-Benz V-класс - это превосходный минивэн, идеально подходящий для комфортных и безопасных поездок для всей семьи и рабочих целей. У Mercedes-Benz V-класса есть достаточно места для семьи, с возможностью вместить до 7 пассажиров. Большой объем багажного отсека позволит удобно разместить все необходимые вещи для длительных поездок или командировок.</p>
                        <p className='modal-text'>Так что, если вам нужен просторный и элегантный автомобиль, который с легкостью вместит всю семью или коллег, минивэн Mercedes-Benz V-класса - отличный выбор!</p>
                    </Box>
                </Modal>
                <Modal
                    keepMounted
                    open={openWedding}
                    onClose={handleCloseWedding}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box className="modal-box">
                        <div className='modal-photo'>
                            <img src={wedding} className="card__photo_img" alt="logo" />
                        </div>
                        <p className='modal-header'>Свадебные мероприятия</p>
                        <p className='modal-text'>Если вы ищете премиальное авто для свадьбы, то Mercedes-Benz Maybach - идеальный выбор! Этот автомобиль сочетает в себе непревзойденный комфорт, роскошный дизайн и высочайший уровень исполнения.</p>
                        <p className='modal-text'>Дополнительно для гостей, вы можете заказать минивены Mercedes-Benz V-classe, а мы со своей стороны обеспечим вам максимальный комфорт и безопасную езду.</p>
                    </Box>
                </Modal>
                <Modal
                    keepMounted
                    open={openEvents}
                    onClose={handleCloseEvents}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box className="modal-box">
                        <div className='modal-photo'>
                            <img src={moreAuto} className="card__photo_img" alt="logo" />
                        </div>
                        <p className='modal-header'>Обслуживание мероприятий</p>
                        <p className='modal-text'>Наша компания предлагает превосходное обслуживание мероприятий и подачу премиальных автомобилей, чтобы удовлетворить самые высокие стандарты качества и комфорта.</p>
                        <p className='modal-text'>Мы гордимся широким выбором премиальных автомобилей, таких как Mercedes-Benz, BMW, Audi и другие роскошные марки. Все наши автомобили находятся в отличном состоянии и оснащены самыми современными технологическими возможностями, чтобы обеспечить вам комфорт и безопасность во время вашего мероприятия.</p>
                        <p className='modal-text'>Наши профессиональные водители обладают опытом и знаниями, чтобы гарантировать плавное и эффективное перемещение между точками вашего мероприятия. Они будут точно выполнять указания и быть внимательными к вашим потребностям, чтобы ваше мероприятие прошло безупречно.</p>
                    </Box>
                </Modal>
                <Modal
                    keepMounted
                    open={openTrezv}
                    onClose={handleCloseTrezv}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box className="modal-box">
                        <div className='modal-photo'>
                            <img src={driver} className="card__photo_img" alt="logo" />
                        </div>
                        <p className='modal-header'>Услуга "Трезвый водитель"</p>
                        <p className='modal-text'>Наша компания предоставляет услугу "Трезвый водитель" для тех, кто хочет насладиться ночью в городе, не беспокоясь о собственной безопасности и безопасности других на дороге.</p>
                        <p className='modal-text'>Наша цель - обеспечить вам комфорт и безопасность во время поездки. Мы гарантируем, что наши водители будут ответственными во время всего маршрута. Вы можете расслабиться и насладиться вечером, зная, что у вас есть надежный водитель, который доставит вас домой безопасно.</p>
                    </Box>
                </Modal>
                <ModalContacts openContacts={openContacts} handleCloseContacts={handleCloseContacts}/>
                <Modal
                    keepMounted
                    open={openSuccess}
                    onClose={handleCloseSuccess}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box className="modal-box-order-success">
                        <p className='modal-header'>Заказ успешно отправлен!</p>
                        <p className='modal-text-order-success'>В ближайшее время с вами свяжется наш менеджер для подтверждения заказа и уточнения деталей.</p>
                        <div className='navbar-text-end' >
                            <div>
                                <a href='https://instagram.com/luxury.black.car?igshid=MzRlODBiNWFlZA==' className='icon-nav-a'><InstagramIcon className='icon-contacts' /></a>
                                <a href='https://wa.me/79165252874?text=Добрый день, хочу заказать у вас автомобиль, увидел у вас на сайте - ' className='icon-nav-a'><WhatsAppIcon className='icon-contacts' /></a>
                                <a href={AppConfig.Site.Links.telegram} className='icon-nav-a'><TelegramIcon className='icon-contacts' /></a>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default MainPage;
