import React from 'react'
import '../App.css';
import AppConfig from '../AppConfig';

import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import logowhite from '../img/logo48white.png'

class Footer extends React.Component {
    render() {
        return <section name='footer'>
            <div className='footer'>
                <div className='footer-grid'>
                    <div> <img src={logowhite} className='logo-white' alt="logo" />
                        <p className='foot-text-logo'>
                            Сервис персональных водителей и аренды автомобилей бизнес и премиум класса.
                        </p>
                        <p className='foot-back-text'>© 2024 "RIDE4U". Все права защищены.</p>
                    </div>
                    <div>
                        <p className='foot-text-big'>Навигация</p>
                        <hr></hr>
                        <div className='ft-navigate'>
                            <a className='foot-text'>Оставить заявку</a>
                            <a className='foot-text' href="/#service">Услуги</a>
                            <a className='foot-text' href='/autopark'>Автопарк</a>
                            <a className='foot-text' href='/privacy-policy'>Политика конфиденциальности</a>
                        </div>
                    </div>
                    <div>
                        <p className='foot-text-big'>Контакты</p>
                        <hr></hr>
                        <div className='ft-navigate'>
                            <a className='foot-text' href="tel:+74953745994">+7 (495) 374-59-94</a>
                            <a className='foot-text'>lbc.zakaz@gmail.com</a>
                            <div>
                                <a href='https://instagram.com/luxury.black.car?igshid=MzRlODBiNWFlZA==' className='a-footer-icon' ><InstagramIcon className='icon-footer' /></a>
                                <a href='https://wa.me/79165252874?text=Добрый день, хочу заказать у вас автомобиль, увидел у вас на сайте - ' className='a-footer-icon'><WhatsAppIcon className='icon-footer' /></a>
                                <a href={AppConfig.Site.Links.telegram} className='a-footer-icon'><TelegramIcon className='icon-footer' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }
}

export default Footer;




