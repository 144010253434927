import React from "react";
import AppConfig from "../../AppConfig";
import {Box, Modal} from "@mui/material";
import contacts from "../../img/contact-new.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";

class modalContacts extends React.Component {

    render() {
        return <Modal
            keepMounted
            open={this.props.openContacts}
            onClose={this.props.handleCloseContacts}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box className="modal-box">
                <div className='modal-photo'>
                    <img src={contacts} className="card__photo_img" alt="logo" />
                </div>
                <p className='modal-header'>Контакты</p>
                <p className='modal-text'>Звоните: <a href="tel:+74953745994">+7 (495) 374-59-94</a></p>
                <p className='modal-text'>По всем вопросам пишите: lbc.zakaz@gmail.com</p>
                <p className='modal-text'>Наш адрес: Дубининская ул., 71, стр. 11</p>
                <div className='navbar-text-end' >
                    <div>
                        <a href='https://instagram.com/luxury.black.car?igshid=MzRlODBiNWFlZA==' className='icon-nav-a'><InstagramIcon className='icon-contacts' /></a>
                        <a href='https://wa.me/79165252874?text=Добрый день, хочу заказать у вас автомобиль, увидел у вас на сайте - ' className='icon-nav-a'><WhatsAppIcon className='icon-contacts' /></a>
                        <a href={AppConfig.Site.Links.telegram} className='icon-nav-a'><TelegramIcon className='icon-contacts' /></a>
                    </div>
                </div>
            </Box>
        </Modal>;
    }
}

export default modalContacts;