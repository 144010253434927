import '../../../App.css';
import { useEffect, useState } from 'react';
import AppConfig from "../../../AppConfig";
import { Box, Input, InputLabel, Modal, Select, TextField } from '@mui/material';
import logo from '../../../img/logo48black.png'
import contacts from '../../../img/contact-new.png';
import AdvertisingCar from '../../AdvCar';
import Footer from '../../footer';
import Navbar from '../../navbar';
/* Фотографии автомобиля */
import foto1 from '../../../img/cars/s-classe/s1-card.png';
import foto2 from '../../../img/cars/s-classe/s2-card.png';
import foto3 from '../../../img/cars/s-classe/s3.jpg';
import foto4 from '../../../img/cars/s-classe/s4.jpg';
/* Иконки */
import LuggageIcon from '@mui/icons-material/Luggage';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import DoneIcon from '@mui/icons-material/Done';
import StarIcon from '@mui/icons-material/Star';
import PaletteIcon from '@mui/icons-material/Palette';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

import { Carousel } from 'react-carousel-minimal';
import Cadillac from '../../../img/newCadillac.png'
import w223classe from '../../../img/cars/s-classe-w223/s-classe-w223-main.png';
import maybachclasse from '../../../img/may222-main.png';
import w223mayclasse from '../../../img/w223maybach-main-park.png';
import ModalContacts from "../../modal/ModalContacts";

function Sclassew222() {
    const [openContacts, setOpenContacts] = useState(false);
    const handleOpenContacts = () => setOpenContacts(true);
    const handleCloseContacts = () => setOpenContacts(false);

    const [classeAuto, setClasseAuto] = useState();

    const handleChange = (event) => {
        setClasseAuto(event.target.value);
    };

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const data = [
        {
            image: foto1,
        },
        {
            image: foto2,
        },
        {
            image: foto3,
        },
        {
            image: foto4,
        }
    ];

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }


    return (
        <div className="App">
            <Navbar contacts_onClick={handleOpenContacts} />
            <div className="breadcrumb">
                <a className='breadcrumb__text' href="/">Главная</a>
                <p className='breadcrumb__text'>/</p>
                <a className='breadcrumb__text' href="/autopark">Автопарк</a>
                <p className='breadcrumb__text'>/</p>
                <p className='breadcrumb__text' >Mercedes-Benz S-classe W222</p>
            </div>
            <div className="main_background">
                <section name='service' id="service">
                    <p className='header-name-car'>Mercedes-Benz S-classe W222</p>
                    <div className='gallery-car'>
                        <Carousel
                            data={data}
                            time={10000}
                            width="100%"
                            height="700px"
                            captionStyle={captionStyle}
                            radius="10px"
                            slideNumber={false}
                            slideNumberStyle={slideNumberStyle}
                            captionPosition="bottom"
                            automatic={true}
                            dots={true}
                            pauseIconColor="white"
                            pauseIconSize="40px"
                            slideBackgroundColor="darkgrey"
                            slideImageFit="cover"
                            thumbnails={false}
                            thumbnailWidth="100px"
                            style={{
                                textAlign: "center",
                                maxWidth: "100%",
                                maxHeight: "auto",
                                margin: "40px auto",
                            }}
                        />
                    </div>
                    <div className='settings-car-grid'>
                        <div className='settings-car'>
                            <PaletteIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Цвет кузова</p>
                                <p className='settings-p'>Черный</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <AirlineSeatReclineNormalIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Сидячие места</p>
                                <p className='settings-p'>3 места</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <LuggageIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Кол-во багажа</p>
                                <p className='settings-p'>3 багажа</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <CurrencyRubleIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Цена</p>
                                <p className='settings-p'>от 3500 ₽/час</p>
                            </div>
                        </div>
                    </div>
                    <section name='description' className='description-grid'>
                        <div className='description-car'>
                            <div>
                                <p className='description-header'>Описание S-classe W222</p>
                                <p className='description-p'>Благодаря продуманной архитектуре дизайн интерьера нового поколения S-classe воплощает идеи классического, роскошного и технологического седана. Горизонтальные элементы и линии создают установку, характеризующуюся визуальной широтой и спокойной твёрдостью. Высококачественная и элегантная отделка сочетается с функциональностью и ясностью концепции: дизайн интерьера платформы 222 сочетает в себе комфорт, простор и удобство автомобиля представительского класса. При разработке салона была щедро применена отделка из высокосортных пород древесины; </p>
                                <p className='description-p'>Выбрав нашу компанию для аренды персонального водителя на Mercedes-Benz S-classe W222, вы можете быть уверены, что ваша поездка будет удовлетворять самым высоким стандартам качества и комфорта.</p>
                            </div>
                        </div>
                        <div className='tariff-car'>
                            <div>
                                <p className='description-p'>Стоимость аренды</p>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 3-ти часов</p>
                                    <p className='tariff-p'>3200 ₽/час</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 6-ти часов</p>
                                    <p className='tariff-p'>3000 ₽/час</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 1 дня</p>
                                    <p className='tariff-p'>2800 ₽/час</p>
                                </div>
                                <p className='description-p-2'>Трансферы</p>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Шереметьево</p>
                                    <p className='tariff-p'>6500 ₽</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Внуково</p>
                                    <p className='tariff-p'>6500 ₽</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Домодедово</p>
                                    <p className='tariff-p'>8000 ₽</p>
                                </div>
                                <div className='btn-order-grid'>
                                    <a className='btn-main-order'>Забронировать</a>
                                    <div className='container-btn-grid-2pc'>
                                        <a href='https://wa.me/79165252874?text=Добрый день, меня интересует аренда Mercedes-Benz E300d AMG с персональным водителем.' className='btn-messanger'>Написать в Whatapp</a>
                                        <a href={AppConfig.Site.Links.telegram} className='btn-messanger'>Написать в Telegram</a>
                                    </div>
                                </div>
                                <div className='tariff-grid-'>
                                    <p className='tariff-mp text-margin'>Минимальный заказ от 3-х часов. Стоимость минимального заказа - 3200 ₽/час. Подача за МКАД рассчитывается отдельно. Дальние поездки рассчитываются по километражу, включая часы работы.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <p className='description-header'>Похожие автомобили</p>
                <div className='autopark__grid'>
                    <div className='autopark__card'>
                        <div className='card__photo'>
                            <img src={w223classe} className="card__photo_img" alt="logo" />
                        </div>
                        <div className='card__description'>
                            <p className='card__description_header'>Mercedes-Benz S-classe W223</p>
                            <div className='card__carSpecific'>
                                <div className='carSpecific-man'>
                                    <PeopleIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <LuggageIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <AccessTimeIcon className='icon_carSpecific' title="Минимальное кол-во часов для бронирования" />
                                    <p className='text-specific'> от 3 часов</p>
                                </div>
                            </div>
                            <div className='card_carAction'>
                                <div className='carSpeific-man'>
                                    <p className='carSpeific-man-text'>от <b className="b-price">5000 ₽</b> за час</p>
                                </div>
                                <a className='carSpeific-man' href='/sclasse-w223'>
                                    <button className='card__btn-view'>Посмотреть</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='autopark__card'>
                        <div className='card__photo'>
                            <img src={maybachclasse} className="card__photo_img" alt="logo" />
                        </div>
                        <div className='card__description'>
                            <p className='card__description_header'>Mercedes-Benz Maybach W222</p>
                            <div className='card__carSpecific'>
                                <div className='carSpecific-man'>
                                    <PeopleIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <LuggageIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 2</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <AccessTimeIcon className='icon_carSpecific' />
                                    <p className='text-specific'> от 30 мин.</p>
                                </div>
                            </div>
                            <div className='card_carAction'>
                                <div className='carSpeific-man'>
                                    <p className='carSpeific-man-text'>от <b className="b-price">1700 ₽</b> за час</p>
                                </div>
                                <a className='carSpeific-man' href='/maybach_w222'>
                                    <button className='card__btn-view'>Посмотреть</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/*<div className='autopark__card'>
                        <div className='card__photo'>
                            <img src={w223mayclasse} className="card__photo_img" alt="logo" />
                        </div>
                        <div className='card__description'>
                            <p className='card__description_header'>Mercedes-Benz Maybach W223</p>
                            <div className='card__carSpecific'>
                                <div className='carSpecific-man'>
                                    <PeopleIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <LuggageIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <AccessTimeIcon className='icon_carSpecific' />
                                    <p className='text-specific'> от 5 часов</p>
                                </div>
                            </div>
                            <div className='card_carAction'>
                                <div className='carSpeific-man'>
                                    <p className='carSpeific-man-text'>от <b className="b-price">10 000 ₽</b> за час</p>
                                </div>
                                <a className='carSpeific-man' href='/maybach_w223'>
                                    <button className='card__btn-view'>Посмотреть</button>
                                </a>
                            </div>
                        </div>
                    </div>*/}
                </div>
                <AdvertisingCar />
                <Footer />
                <ModalContacts openContacts={openContacts} handleCloseContacts={handleCloseContacts}/>
            </div>
        </div>
    );
}

export default Sclassew222;
