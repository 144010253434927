import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppConfig from './AppConfig';
import MainPage from './Main';
import Autopark from './Autopark';
import E220dAmg from './pages/cars/e220d_AMG/e220d_amg';
import E200d from './pages/cars/e220d_AMG/e200d';
import Bmw5 from './pages/cars/e220d_AMG/bmw5';
import E220dDorest from './pages/cars/e220d_AMG/e220d-dorest';
import E300dRest from './pages/cars/e220d_AMG/e300d';
import Gls450 from './pages/cars/e220d_AMG/gls450';
import Vclasse from './pages/cars/e220d_AMG/vclasse';
import G63Amg from './pages/cars/e220d_AMG/g63';
import Maybach223 from './pages/cars/e220d_AMG/maybach223';
import Maybach222 from './pages/cars/e220d_AMG/maybach222';
import Sclasse223 from './pages/cars/e220d_AMG/sclasse223';
import Sclassew222 from './pages/cars/e220d_AMG/sclasse222';
import Cadillac from './pages/cars/e220d_AMG/cadillac';
import PrivacyPolicy from './pages/privacy-policy';
import SprinterVip from './pages/cars/e220d_AMG/sprintervip';
import Bus from './pages/cars/e220d_AMG/bus';
import SprinterWhite from './pages/cars/e220d_AMG/sprinterwhite';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/autopark" element={<Autopark />} />
          <Route path="/sclasse-w222" element={<Sclassew222 />} />
          <Route path="/e220d_dorest" element={<E220dDorest />} />
          <Route path='/e220d_AMG' element={<E220dAmg />} />
          <Route path='/e300d_AMG' element={<E300dRest />} />
          <Route path='/e200d' element={<E200d />} />
          <Route path='/bmw5' element={<Bmw5 />} />
          <Route path='/vclasse' element={<Vclasse />} />
          <Route path='/gls450' element={<Gls450 />} />
          <Route path='/g63_AMG' element={<G63Amg />} />
          <Route path='/cadillac_escalade' element={<Cadillac />} />
          <Route path='/sclasse-w223' element={<Sclasse223 />} />
          <Route path='/maybach_w222' element={<Maybach222 />} />
          <Route path='/maybach_w223' element={<Maybach223 />} />
          <Route path='/sprinter-vip' element={<SprinterVip />} />
          <Route path='/sprinter-white-vip' element={<SprinterWhite />} />
          <Route path='/yutong_ZK6122H9' element={<Bus />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Routes>
    </div>
    </BrowserRouter >
  );
}

export default App;



