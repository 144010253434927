import React from 'react'
import '../App.css';
import DoneIcon from '@mui/icons-material/Done';
import StarIcon from '@mui/icons-material/Star';
import Cadillac from '../img/newCadillac.png'

class AdvertisingCar extends React.Component {
    render() {
        return <section className='newCar'>
            <div className='service__header'>
                <p className='header'>Попробуйте</p>
                <p className='header-plain'>Новый автомобиль, танк на московских дорогах. <br /> Популярен в аренде у Госструктур.</p>
            </div>
            <div className='newCar__grid'>
                <div className='newCar-specifications'>
                    <p className='header__newCar'>Cadillac Escalade</p>
                    <div className='specif-grid'>
                        <div>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />Полный привод</p>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />2022 года выпуска</p>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />7 мест</p>
                        </div>
                        <div>
                            <p className='carNew__description_plain'> <DoneIcon className='icon-succes' />Для деловых поездок</p>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />Идеален для свадьбы</p>
                            <p className='carNew__description_plain'><StarIcon className='icon-succes' />Популярен у Госструктур</p>
                        </div>
                    </div>
                    <a className='btn__newCar' href='/cadillac_escalade'>Посмотреть детально</a>
                </div>
                <div className='newCar-img-block'>
                    <img src={Cadillac} className="newCar-img" alt="logo" />
                </div>
            </div>
        </section>;
    }
}

export default AdvertisingCar;