import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAQjlSHbhbT8Ih-9baAjNVNcEOK-qAI7zk",
    authDomain: "luxury-3d16a.firebaseapp.com",
    projectId: "luxury-3d16a",
    storageBucket: "luxury-3d16a.appspot.com",
    messagingSenderId: "572371658200",
    appId: "1:572371658200:web:74181b876c85f5e4f3fead",
    measurementId: "G-DDT0PWPSZ0"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
