import React from 'react'
import '../App.css';
import DoneIcon from '@mui/icons-material/Done';
import StarIcon from '@mui/icons-material/Star';
import SprinterAdv from '../img/cars/sprinter-vip/sprinter-vip-adv.png'

class AdvertisingCarSprinter extends React.Component {
    render() {
        return <section className='newCar'>
            <div className='service__header'>
                <p className='header'>Попробуйте</p>
                <p className='header-plain'>Нужен вместительный микроавтобус? <br /> Заказывайте быстрее у нас!</p>
            </div>
            <div className='newCar__grid'>
                <div className='newCar-specifications'>
                    <p className='header__newCar'>Mercedes-Benz Sprinter</p>
                    <div className='specif-grid'>
                        <div>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />Полный привод</p>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />2022 года выпуска</p>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />4 места</p>
                        </div>
                        <div>
                            <p className='carNew__description_plain'> <DoneIcon className='icon-succes' />Для большой компании</p>
                            <p className='carNew__description_plain'><DoneIcon className='icon-succes' />Идеален для свадьбы</p>
                            <p className='carNew__description_plain'><StarIcon className='icon-succes' />Популярен у Госструктур</p>
                        </div>
                    </div>
                    <a className='btn__newCar' href='/sprinter-vip'>Посмотреть детально</a>
                </div>
                <div className='newCar-img-block'>
                    <img src={SprinterAdv} className="newCar-img" alt="logo" />
                </div>
            </div>
        </section>;
    }
}

export default AdvertisingCarSprinter;