import React, { useState } from 'react';
import AppConfig from "../AppConfig";
import { setState } from 'react';
import { Box, Modal } from '@mui/material';
import '../App.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import logoblack from '../img/logo48black.png'
import contacts from '../img/contact-new.png'

class Navbar extends React.Component {

    render() {
        return <section name='footer'>
            <nav className='navbar'>
                <div>
                    <img src={logoblack} className='logo' alt="logo" />
                    <a className='navbar__text-logo'></a>
                </div>
                <div className='navbar-text-center'>
                    <a className='navbar__text' href="/#service">Услуги</a>
                    <a className='navbar__text' href='/autopark'>Автопарк</a>
                    <a className='navbar__text' href="javascript:void(0)" onClick={() => {
                        this.props.contacts_onClick();
                    }}>Контакты</a>
                </div>
                <div className='navbar-text-end' >
                    <div>
                        <a href='https://instagram.com/luxury.black.car?igshid=MzRlODBiNWFlZA==' className='icon-nav-a'><InstagramIcon className='icon-navbar' /></a>
                        <a href='https://wa.me/79165252874?text=Добрый день, хочу заказать у вас автомобиль, увидел у вас на сайте - ' className='icon-nav-a'><WhatsAppIcon className='icon-navbar' /></a>
                        <a href={AppConfig.Site.Links.telegram} className='icon-nav-a'><TelegramIcon className='icon-navbar' /></a>
                    </div>
                    <a className='navbar__text-end' href="tel:+74953745994">+7 (495) 374-59-94</a>
                </div>
            </nav>
        </section>;
    }
}

export default Navbar;




