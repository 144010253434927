
const AppConfig = {
    'Site' : {
        'Links': {
            'whatsapp': 'https://wa.me/79165252874?text=Добрый день, хочу заказать у вас автомобиль, увидел у вас на сайте - ',
            'telegram': 'https://t.me/orderRIDE4U'
        }
    }
};

export default AppConfig;