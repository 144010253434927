import './App.css';

import AppConfig from './AppConfig';

import Footer from './pages/footer';
import Navbar from './pages/navbar';
import ModalContacts from "./pages/modal/ModalContacts";

import logo from './img/logo48black.png'
import vClass from './img/cars/v-classe/v-classe-main.png';
import cadillacmain from './img/cars/Cadillac Escalade/Cadillac-main.png';
import e200d from './img/cars/e-classe e200d/main.png';
import e220dDorest from './img/cars/e-classe e220d dorest/e220d-dorest-main.png';
import eclasse from './img/w213new-main-park.png';
import e300d from './img/cars/e-classe e300d amg/e300d-main.png';
import sclasse from './img/s-main-park.png';
import gls450 from './img/cars/gls-classe 450/gls450-main.png';
import g63classe from './img/cars/g63-classe/g63-classe-main.png';
import w223classe from './img/cars/s-classe-w223/s-classe-w223-main.png';
import maybachclasse from './img/may222-main.png';
import w223mayclasse from './img/w223maybach-main-park.png';
import sprinterwhite from './img/cars/sprinter-white/sprinter-white-main.png';
import sprintervip from './img/cars/sprinter-vip/sprinter-vip-main.png';
import bus from './img/cars/bus/bus-main.png';

import PeopleIcon from '@mui/icons-material/People';
import LuggageIcon from '@mui/icons-material/Luggage';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useState } from 'react';
import { Box, Input, InputLabel, Modal, Select, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import CarRentalIcon from '@mui/icons-material/CarRental';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

function Autopark() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openVan, setOpenVan] = useState(false);
    const handleOpenVan = () => setOpenVan(true);
    const handleCloseVan = () => setOpenVan(false);

    const [openWedding, setOpenWedding] = useState(false);
    const handleOpenWedding = () => setOpenWedding(true);
    const handleCloseWedding = () => setOpenWedding(false);

    const [openEvents, setOpenEvents] = useState(false);
    const handleOpenEvents = () => setOpenEvents(true);
    const handleCloseEvents = () => setOpenEvents(false);

    const [openTrezv, setOpenTrezv] = useState(false);
    const handleOpenTrezv = () => setOpenTrezv(true);
    const handleCloseTrezv = () => setOpenTrezv(false);

    const [openContacts, setOpenContacts] = useState(false);
    const handleOpenContacts = () => setOpenContacts(true);
    const handleCloseContacts = () => setOpenContacts(false);

    const [classeAuto, setClasseAuto] = useState();

    const handleChange = (event) => {
        setClasseAuto(event.target.value);
    };

    return (
        <div className="App">

            <div className="main_background">
                <Navbar contacts_onClick={handleOpenContacts} />
                <section name='service'>
                    <div className='service__header-autopark'>
                        <p className='header'>Автопарк</p>
                        <p className='header-plain'>Приглашаем вас опробывать наши автомобили,<br /> мы гарантируем что вы останетесь довольны.</p>
                    </div>
                    <div className='autopark__grid'>
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={vClass} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz V-classe</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 6</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 5</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">1 650 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/vclasse'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={e220dDorest} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz e220d dorest.</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 2</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 2 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">1 500 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/e220d_dorest'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/*<div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={e200d} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz E200d</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 2</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 2 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">1 500 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/e200d'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>*/}
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={eclasse} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz E220d AMG</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 2</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 2 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">1 500 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/e220d_amg'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={e300d} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz E300d AMG</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 2</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">1 900 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/e300d_AMG'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={sclasse} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz S-classe W222</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">2 800 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/sclasse-w222'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={w223classe} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz S-classe W223</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' title="Минимальное кол-во часов для бронирования" />
                                        <p className='text-specific'> от 3 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">4 000 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/sclasse-w223'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={maybachclasse} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz Maybach W222</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">3 200 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/maybach_w222'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/*<div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={w223mayclasse} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz Maybach W223</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 4-х часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">10 000 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/maybach_w223'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>*/}

                        {/*<div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={gls450} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz GLS450</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">6 500 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/gls450'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={cadillacmain} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Cadillac Escalade</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'>7</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'>3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 3 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">6 500 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/cadillac_escalade'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={g63classe} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz G63 AMG</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 3</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 5 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">8 000 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/g63_AMG'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                    <div className='service__header-autopark'>
                        <p className='header'>Больше мест</p>
                        <p className='header-plain'>Микроавтобусы и автобусы,<br />если нужно перевезти много людей, обращайтесь к нам.</p>
                    </div>
                    <div className='autopark__grid'>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={sprinterwhite} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz Sprinter White</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 19</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 10</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 4 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">2 000 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/sprinter-white-vip'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={sprintervip} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Mercedes-Benz Sprinter VIP</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 19</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 10</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 4 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">3 500 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/sprinter-vip'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='autopark__card'>
                            <div className='card__photo'>
                                <img src={bus} className="card__photo_img" alt="logo" />
                            </div>
                            <div className='card__description'>
                                <p className='card__description_header'>Автобус Yutong ZK6122H9</p>
                                <div className='card__carSpecific'>
                                    <div className='carSpecific-man'>
                                        <PeopleIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 50</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <LuggageIcon className='icon_carSpecific' />
                                        <p className='text-specific'> 50</p>
                                    </div>
                                    <div className='carSpecific-bag'>
                                        <AccessTimeIcon className='icon_carSpecific' />
                                        <p className='text-specific'> от 4 часов</p>
                                    </div>
                                </div>
                                <div className='card_carAction'>
                                    <div className='carSpeific-man'>
                                        <p className='carSpeific-man-text'>от <b className="b-price">2 700 ₽</b> за час</p>
                                    </div>
                                    <a className='carSpeific-man' href='/yutong_ZK6122H9'>
                                        <button className='card__btn-view'>Посмотреть</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <ModalContacts openContacts={openContacts} handleCloseContacts={handleCloseContacts}/>
                <Footer />
            </div>
        </div>

    );
}

export default Autopark;
