import '../../../App.css';
import { useEffect, useState } from 'react';
import AppConfig from "../../../AppConfig";
import { Box, Input, InputLabel, Modal, Select, TextField } from '@mui/material';
import logo from '../../../img/logo48black.png'
import contacts from '../../../img/contact-new.png';
import AdvertisingCarG63 from '../../AdvCarG63';
import Footer from '../../footer';
import Navbar from '../../navbar';
/* Фотографии автомобиля */
import foto1 from '../../../img/cars/bus/bus-1.png';
import foto2 from '../../../img/cars/bus/bus-2.png';
import foto3 from '../../../img/cars/bus/bus-3.png';
import foto4 from '../../../img/cars/bus/bus-4.png';
import LuggageIcon from '@mui/icons-material/Luggage';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import DoneIcon from '@mui/icons-material/Done';
import StarIcon from '@mui/icons-material/Star';
import PaletteIcon from '@mui/icons-material/Palette';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

import { Carousel } from 'react-carousel-minimal';
import Cadillacfoto from '../../../img/newCadillac.png'
import gls450 from '../../../img/cars/gls-classe 450/gls450-main.png';
import g63classe from '../../../img/cars/g63-classe/g63-classe-main.png';
import ModalContacts from "../../modal/ModalContacts";

function Bus() {
    const [openContacts, setOpenContacts] = useState(false);
    const handleOpenContacts = () => setOpenContacts(true);
    const handleCloseContacts = () => setOpenContacts(false);

    const [classeAuto, setClasseAuto] = useState();

    const handleChange = (event) => {
        setClasseAuto(event.target.value);
    };

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const data = [
        {
            image: foto1,
        },
        {
            image: foto2,
        },
        {
            image: foto3,
        },
        {
            image: foto4,
        }
    ];

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }

    const [newDate, setNewDate] = useState(""); // Дата начала 
    const [newName, setNewName] = useState(""); // Имя заказчика
    const [newPhone, setNewPhone] = useState(""); // Номер телефона

    const [openOrder, setOpenOrder] = useState(false);
    const handleOpenOrder = () => setOpenOrder(true);
    const handleCloseOrder = () => setOpenOrder(false);


    return (
        <div className="App">
            <Navbar contacts_onClick={handleOpenContacts} />
            <div className="breadcrumb">
                <a className='breadcrumb__text' href="/">Главная</a>
                <p className='breadcrumb__text'>/</p>
                <a className='breadcrumb__text' href="/autopark">Автопарк</a>
                <p className='breadcrumb__text'>/</p>
                <p className='breadcrumb__text' >Автобус Yutong ZK6122H9</p>
            </div>
            <div className="main_background">
                <section name='service' id="service">
                    <p className='header-name-car'>Автобус Yutong ZK6122H9</p>
                    <div className='gallery-car'>
                        <Carousel
                            data={data}
                            time={10000}
                            width="100%"
                            height="700px"
                            captionStyle={captionStyle}
                            radius="10px"
                            slideNumber={false}
                            slideNumberStyle={slideNumberStyle}
                            captionPosition="bottom"
                            automatic={true}
                            dots={true}
                            pauseIconColor="white"
                            pauseIconSize="40px"
                            slideBackgroundColor="darkgrey"
                            slideImageFit="cover"
                            thumbnails={false}
                            thumbnailWidth="100px"
                            style={{
                                textAlign: "center",
                                maxWidth: "100%",
                                maxHeight: "auto",
                                margin: "40px auto",
                            }}
                        />
                    </div>
                    <div className='settings-car-grid'>
                        <div className='settings-car'>
                            <PaletteIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Цвет кузова</p>
                                <p className='settings-p'>Белый</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <AirlineSeatReclineNormalIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Сидячие места</p>
                                <p className='settings-p'>50 мест</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <LuggageIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Кол-во багажа</p>
                                <p className='settings-p'>50 багажа</p>
                            </div>
                        </div>
                        <div className='settings-car'>
                            <CurrencyRubleIcon className='icon-settinga-car-card' />
                            <div>
                                <p className='settings-pm'>Цена</p>
                                <p className='settings-p'>от 2700 ₽/час</p>
                            </div>
                        </div>
                    </div>
                    <section name='description' className='description-grid'>
                        <div className='description-car'>
                            <div>
                                <p className='description-header'>Описание Автобуса Yutong ZK6122H9</p>
                                <p className='description-p'>Наша компания предлагает аренду автобуса Yutong ZK6122H9 для различных мероприятий и пассажирских перевозок. Мы гарантируем высокое качество обслуживания и безопасность во время вашей поездки. Наш опытный водитель будет гарантировать плавное и безопасное движение, а также предложит вам высокий уровень комфорта.</p>
                                <p className='description-p'>Обратитесь к нам прямо сейчас, чтобы организовать аренду автобуса Yutong ZK6122H9 и насладиться комфортабельной и безопасной поездкой для ваших пассажиров. Мы готовы предоставить вам высокий уровень обслуживания и удовлетворить все ваши потребности в перевозках.</p>
                            </div>
                        </div>
                        <div className='tariff-car'>
                            <div>
                                <p className='description-p'>Стоимость аренды</p>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 3-ти часов</p>
                                    <p className='tariff-p'>2700 ₽/час + 2ч. подачи</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 6-ти часов</p>
                                    <p className='tariff-p'>2700 ₽/час + 2ч. подачи</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Аренда от 1 дня</p>
                                    <p className='tariff-p'>2700 ₽/час + 2ч. подачи</p>
                                </div>
                                <p className='description-p-2'>Трансферы</p>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Шереметьево</p>
                                    <p className='tariff-p'>18900 ₽</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Внуково</p>
                                    <p className='tariff-p'>18900 ₽</p>
                                </div>
                                <div className='tariff-grid'>
                                    <p className='tariff-mp'>Домодедово</p>
                                    <p className='tariff-p'>21900 ₽</p>
                                </div>
                                <div className='btn-order-grid'>
                                    <a className='btn-main-order' onClick={() => {
                                        handleOpenOrder();
                                    }}>Забронировать</a>
                                    <div className='container-btn-grid-2pc'>
                                        <a href='https://wa.me/79165252874?text=Добрый день, меня интересует аренда Mercedes-Benz E300d AMG с персональным водителем.' className='btn-messanger'>Написать в Whatapp</a>
                                        <a href={AppConfig.Site.Links.telegram} className='btn-messanger'>Написать в Telegram</a>
                                    </div>
                                </div>
                                <div className='tariff-grid-'>
                                    <p className='tariff-mp text-margin'>Минимальный заказ от 4-х часов + 2 часа подачи. Стоимость минимального заказа - 2700 ₽/час. Подача за МКАД рассчитывается отдельно. Дальние поездки рассчитываются по километражу, включая часы работы.  </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <p className='description-header'>Похожие автомобили</p>
                <div className='autopark__grid'>
                    <div className='autopark__card'>
                        <div className='card__photo'>
                            <img src={gls450} className="card__photo_img" alt="logo" />
                        </div>
                        <div className='card__description'>
                            <p className='card__description_header'>Mercedes-Benz GLS450</p>
                            <div className='card__carSpecific'>
                                <div className='carSpecific-man'>
                                    <PeopleIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <LuggageIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <AccessTimeIcon className='icon_carSpecific' />
                                    <p className='text-specific'> от 5 часов</p>
                                </div>
                            </div>
                            <div className='card_carAction'>
                                <div className='carSpeific-man'>
                                    <p className='carSpeific-man-text'>от <b className="b-price">6000 ₽</b> за час</p>
                                </div>
                                <a className='carSpeific-man' href='/gls450'>
                                    <button className='card__btn-view'>Посмотреть</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='autopark__card'>
                        <div className='card__photo'>
                            <img src={g63classe} className="card__photo_img" alt="logo" />
                        </div>
                        <div className='card__description'>
                            <p className='card__description_header'>Mercedes-Benz G63 AMG</p>
                            <div className='card__carSpecific'>
                                <div className='carSpecific-man'>
                                    <PeopleIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <LuggageIcon className='icon_carSpecific' />
                                    <p className='text-specific'> 3</p>
                                </div>
                                <div className='carSpecific-bag'>
                                    <AccessTimeIcon className='icon_carSpecific' />
                                    <p className='text-specific'> от 5 часов</p>
                                </div>
                            </div>
                            <div className='card_carAction'>
                                <div className='carSpeific-man'>
                                    <p className='carSpeific-man-text'>от <b className="b-price">7000 ₽</b> за час</p>
                                </div>
                                <a className='carSpeific-man' href='/g63_AMG'>
                                    <button className='card__btn-view'>Посмотреть</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <AdvertisingCarG63 />
                <Footer />
                <ModalContacts openContacts={openContacts} handleCloseContacts={handleCloseContacts}/>
                <Modal
                    keepMounted
                    open={openOrder}
                    onClose={handleCloseOrder}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box className="modal-box-order-success">
                        <p className='modal-header'>Карточка заказа</p>
                        <div className='box-order-input'>
                            <InputLabel shrink htmlFor="date_delivery" className='labelInput-inCardAuto'>
                                Дата подачи:
                            </InputLabel>
                            <TextField id="date_delivery" size="small" type='date' className='dateAuto-input' onChange={(event) => {
                                setNewDate(event.target.value);
                            }} />
                        </div>
                        <div className='box-order-input'>
                            <InputLabel shrink htmlFor="bootstrap-input" className='labelInput-inCardAuto'>
                                Ваше имя:
                            </InputLabel>
                            <TextField size="small" type='text' className='classeAuto-input' onChange={(event) => {
                                setNewName(event.target.value);
                            }} />
                        </div>
                        <div className='box-order-input'>
                            <InputLabel shrink htmlFor="bootstrap-input" className='labelInput-inCardAuto'>
                                Ваш телефон:
                            </InputLabel>
                            <TextField size="small" type='phone' className='classeAuto-input' onChange={(event) => {
                                setNewPhone(event.target.value);
                            }} />
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default Bus;
